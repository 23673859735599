import React, { useState, useEffect } from 'react';
import './App.css'; // Import the CSS file

function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [inputText, setInputText] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  useEffect(() => {
    // Fetch phone numbers from the backend
    const fetchPhoneNumbers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/phone-numbers`);
        const data = await response.json();
        setPhoneNumbers(data);
      } catch (error) {
        console.error('Error fetching phone numbers:', error);
      }
    };

    fetchPhoneNumbers();
  }, []);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleRBMButtonClick = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send-rbm-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber, text: inputText }),
      });
      const data = await response.json();
      if (response.ok) {
        setResponseMessage(data.message);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRichCardButtonClick = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send-rbm-richcard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber }),
      });
      const data = await response.json();
      if (response.ok) {
        setResponseMessage(data.message);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCalendarButtonClick = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send-rbm-calendar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber }),
      });
      const data = await response.json();
      if (response.ok) {
        setResponseMessage(data.message);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSMSButtonClick = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/send-sms-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber, text: inputText }),
      });
      const data = await response.json();
      if (response.ok) {
        setResponseMessage(data.message);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSendToAll = async (endpoint) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText }),
      });
      const data = await response.json();
      if (response.ok) {
        setResponseMessage(data.message);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>RBM Demo</h1>
        <div className="input-container">
          <select
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className="input-field"
          >
            <option value="">Select a phone number</option>
            {phoneNumbers.map((phone) => (
              <option key={phone.number} value={phone.number}>
                {phone.label} {phone.number}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={inputText}
            onChange={handleInputChange}
            placeholder="Enter text"
            className="input-field"
          />
        </div>
        <div className="button-container">
          <button 
            onClick={handleSMSButtonClick} 
            className="send-button"
            disabled={!phoneNumber} // Disable if no phone number is selected
          >
            Send SMS Message
          </button>
          <button 
            onClick={handleRBMButtonClick} 
            className="send-button"
            disabled={!phoneNumber} // Disable if no phone number is selected
          >
            Send RBM Message
          </button>
          <button 
            onClick={handleRichCardButtonClick} 
            className="send-button"
            disabled={!phoneNumber} // Disable if no phone number is selected
          >
            Send RBM Richcard
          </button>
          <button 
            onClick={handleCalendarButtonClick} 
            className="send-button"
            disabled={!phoneNumber} // Disable if no phone number is selected
          >
            Send RBM CalEntry
          </button>
          <button 
            onClick={() => handleSendToAll('send-sms-to-all')} 
            className="send-button"
            disabled={phoneNumbers.length === 0} // Disable if no phone numbers are available
          >
            Send SMS to All
          </button>
          <button 
            onClick={() => handleSendToAll('send-rbm-to-all')} 
            className="send-button"
            disabled={phoneNumbers.length === 0} // Disable if no phone numbers are available
          >
            Send RBM to All
          </button>
        </div>
        {responseMessage && (
          <p className="response-message">Response: {responseMessage}</p>
        )}
      </header>
      <footer className="App-footer">
        <p className="imprint">Provided by Telekom Global Carrier | Powered by NWI</p>
        <p className="imprint">In case of questions contact <a href="mailto:cpaas@telekom.de" className="magenta-link">cpaas@telekom.de</a></p>
      </footer>
    </div>
  );
}

export default App;
